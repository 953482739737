import React from "react"
import { graphql, Link } from "gatsby"
import { SectionTitle } from "../../components/styled"
import { FaAngleRight } from "react-icons/fa"
import { Seo } from "../../components/seo"
import { format } from "date-fns"
import Contact from "../../components/Contact"
import { languageData } from "../../translate/traduction"
const JobDetails = ({ data }) => {
  const dataJobs = data.allStrapiJobs.nodes
  const actualDate = format(new Date(), "yyyy-MM-dd")
  const jobDate = dataJobs[0].DatePublication
  const actualDate2 = actualDate.split("-")
  const jobDate2 = jobDate.split("-")
  const sub1 = actualDate2[0] * 365 + actualDate2[1] * 30 + actualDate2[2]
  const sub2 = jobDate2[0] * 365 + jobDate2[1] * 30 + jobDate2[2]
  const rest = sub1 - sub2
  const { Imposible, Negotiable, Posible } = dataJobs[0].NewGraduates
    ? dataJobs[0].NewGraduates
    : ""
  const { None, ThereRegulation, Yes } = dataJobs[0].VisaSupport
    ? dataJobs[0].VisaSupport
    : ""
  const noImage = require("../../images/icons/no-imagen.svg")
  const handleAddBrTag = string => {
    if (string === null) {
      return ""
    }
    return string.replace(/(\r\n|\n)/gm, "<br />")
  }
  return (
    <>
      <Seo
        title={`${data.allStrapiJobs.nodes[0].Title} | Job offers/career changes/jobs information in Mexico [QUICK GLOBAL MEXICO]`}
        image={
          data.allStrapiJobs.nodes[0].types[0]
            ? data.allStrapiJobs.nodes[0].types[0].image.url
            : ""
        }
      />
      <section className="container jobs-listing">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-9" style={{ padding: 0 }}>
            <div className="jobs-listing__job-info jobs-listing__job-details">
              {dataJobs[0].Archived === true && (
                <>
                  <div style={{ marginTop: "1em" }}>
                    <SectionTitle>
                      Applications for this job have been closed.
                    </SectionTitle>
                    <hr className="u-line-bottom" />
                    <p className="u-margin-bottom-1 u-margin-top-1">
                      Latest job information,
                      <Link to="/en/jobs-listing" className="u-color-danger">
                        Jobs listing
                      </Link>
                      please check here.
                    </p>
                  </div>
                  <hr className="u-line-bottom" />
                  <div className="job-close-text">
                    <p>
                      <b>
                        If you would like to discuss your requirements,
                        <br />
                        If you register below, a staff member will introduce you
                        to job openings.
                        <br />
                      </b>
                      ※ For those who have already had an interview with us,
                      please contact your interviewer.
                    </p>
                    <div className="btns-job-close">
                      <Link
                        to="/en/job-form"
                        state={{ workid: dataJobs[0].Manualid }}
                      >
                        Free consultation form
                      </Link>
                    </div>
                  </div>
                </>
              )}

              <hr className="u-line-bottom" />
              <>
                {dataJobs.map(jobs => (
                  <>
                    <div className="container-full jobs-listing__job-info--box-job">
                      <Link
                        className="u-display-text-head"
                        to={`/en/jobs-listing/${jobs.Manualid}`}
                      >
                        {jobs.Title}
                      </Link>
                      <div className="row">
                        <div className="jobs-listing__job-info--image  col-4 col-sm-3">
                          <div className="box-nro-item">
                            {jobs.types[0] ? (
                              <img src={jobs.types[0].image.url} alt="" />
                            ) : (
                              <img className="no-imagen" src={noImage} alt="" />
                            )}
                            <span>No. {jobs.Manualid}</span>
                          </div>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--tags">
                          <h1 className="u-display-text">
                            <Link to={`/en/jobs-listing/${jobs.Manualid}`}>
                              {jobs.Title}
                            </Link>
                          </h1>
                          <ul>
                            {rest <= 7 && <li className="new-tag">NEW</li>}
                            {jobs.marks && (
                              <>
                                {jobs.marks.map((marker, i) => (
                                  <li key={i}>
                                    {/* className="active-tag" */}
                                    <Link to={`/en/job-search/${marker.name}`}>
                                      {
                                        languageData["en"].modal.conditions[
                                          marker.name
                                        ]
                                      }
                                    </Link>
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Job Category</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          {jobs.types && (
                            <>
                              {jobs.types.map((type, i) => (
                                <span key={i}>
                                  <Link to={`/en/jobs-types/${type.name}`}>
                                    {languageData["en"].modal.jobs[type.name]}
                                  </Link>
                                </span>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Job Title</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          <span>
                            <Link to={`/en/jobs-listing/${jobs.JobCategorie}`}>
                              {jobs.JobCategorie}
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Location</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description jobs-listing__job-info--description--display">
                          {jobs.locations && (
                            <>
                              {jobs.locations.map((location, i) => (
                                <>
                                  {location.name !== "その他" &&
                                  location.name !== "日本" ? (
                                    <>
                                      {i === 0 && (
                                        <span style={{ marginRight: "5px" }}>
                                          Mexico /
                                        </span>
                                      )}
                                      <span
                                        key={i}
                                        style={{ marginRight: "5px" }}
                                      >
                                        <Link
                                          to={`/en/jobs-location/${location.name}`}
                                        >
                                          {
                                            languageData["en"].modal
                                              .locationsSub[location.name]
                                          }
                                        </Link>
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span
                                        key={i}
                                        style={{ marginRight: "5px" }}
                                      >
                                        <Link
                                          to={`/en/jobs-location/${location.name}`}
                                        >
                                          {location.name}
                                        </Link>
                                      </span>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          )}
                          <span>{jobs.LocationDescription}</span>
                        </div>
                      </div>
                      {jobs.JobDescription && (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>Job Description</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(jobs.JobDescription),
                              }}
                            ></span>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Salary (Gross)</span>
                        </div>
                        <div className="col-md-3 col-sm-3 col-8 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: jobs.Salary,
                            }}
                          ></span>
                        </div>
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title jobs-listing__job-info--title--width">
                          <span>Salary Increase/Bonus</span>
                        </div>
                        <div className="col-4 col-sm-3 col-8 jobs-listing__job-info--description jobs-listing__job-info--description--width">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: handleAddBrTag(jobs.SalaryIncrease),
                            }}
                          ></span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Working Hours</span>
                        </div>
                        <div className="col-md-3 col-sm-3 col-8 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: handleAddBrTag(jobs.WorkingTime),
                            }}
                          ></span>
                        </div>
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title jobs-listing__job-info--title--width">
                          <span>Holidays/Vacation </span>
                        </div>
                        <div className="col-4 col-sm-3 col-8 jobs-listing__job-info--description jobs-listing__job-info--description--width">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: jobs.HolidayVacation,
                            }}
                          ></span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Social Insurance/Optional Insurance</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: handleAddBrTag(jobs.Insurance),
                            }}
                          ></span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Allowances</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: handleAddBrTag(jobs.Allowances),
                            }}
                          ></span>
                        </div>
                      </div>
                      {jobs.MandatoryRequirement && (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>Requirements</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(
                                  jobs.MandatoryRequirement
                                ),
                              }}
                            ></span>
                          </div>
                        </div>
                      )}

                      {jobs.WelcomeRequirement && (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>Welcome Requirements</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(jobs.WelcomeRequirement),
                              }}
                            ></span>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className=" col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Company Overview </span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: jobs.CompanyProfile,
                            }}
                          ></span>
                        </div>
                      </div>
                      {jobs.NewGraduates && jobs.VisaSupport && (
                        <div className="row jobs-listing__buttons-description">
                          <p
                            className={`jobs-listing__buttons-description--${
                              (Posible && !Imposible && !Negotiable) ||
                              (!Posible && !Imposible && Negotiable)
                                ? "positive"
                                : "negative" ||
                                  (!Posible && Imposible && !Negotiable)
                                ? "negative"
                                : ""
                            }`}
                          >
                            New graduate student application
                            <span>
                              {Posible && !Imposible && !Negotiable
                                ? "yes"
                                : "" || (!Posible && !Imposible && Negotiable)
                                ? "negociable"
                                : "" || (!Posible && Imposible && !Negotiable)
                                ? "no"
                                : ""}
                            </span>
                          </p>
                          <p
                            className={`jobs-listing__buttons-description--${
                              (Yes && !ThereRegulation && !None) ||
                              (!Yes && ThereRegulation && !None)
                                ? "positive"
                                : "negative" ||
                                  (!Yes && !ThereRegulation && None)
                                ? "negative"
                                : ""
                            }`}
                          >
                            Visa acquisition support
                            <span>
                              {Yes && !ThereRegulation && !None
                                ? "can be"
                                : "" || (!Yes && ThereRegulation && !None)
                                ? "regulations apply"
                                : "" || (!Yes && !ThereRegulation && None)
                                ? "none"
                                : ""}
                            </span>
                          </p>
                        </div>
                      )}

                      <div className="row jobs-listing__text-description">
                        <b>
                          Details will be provided during the registration
                          interview. If you wish to register, click below.
                        </b>
                        <br></br>
                        <span className="jobs-listing__text-description--font">
                          ※ For those who have already had an interview with us,
                          please contact your interviewer.
                        </span>
                      </div>
                      <div className="row jobs-listing__links">
                        <Link
                          to="/en/job-form"
                          state={{ workid: dataJobs[0].Manualid }}
                        >
                          <FaAngleRight />
                          Get a Free Consultation
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </>
            </div>

            <Contact lang={"en"} />
          </div>
        </div>
      </section>
    </>
  )
}

export default JobDetails

export const query = graphql`
  query JobDetailsQueryEn($slug: String!) {
    allStrapiJobs(filter: { Manualid: { eq: $slug } }) {
      nodes {
        Allowances
        Archived
        CompanyProfile
        DatePublication
        HolidayVacation
        Insurance
        JobDescription
        Manualid
        Salary
        SalaryIncrease
        MandatoryRequirement
        WelcomeRequirement
        Title
        WorkingTime
        JobCategorie
        LocationDescription
        NewGraduates {
          Imposible
          Negotiable
          Posible
        }
        locations {
          name
        }
        marks {
          name
        }
        types {
          name
          image {
            url
          }
        }
        VisaSupport {
          None
          ThereRegulation
          Yes
        }
        Archived
        DatePublication
      }
    }
  }
`
